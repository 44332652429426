<script>
import AuthTemplate from '../AuthTemplate.vue'
import RegistrationForm from './RegistrationForm.vue'
export default {
  name: 'RegistrationPage',
  components: {
    AuthTemplate,
    RegistrationForm
  },
  data () {
    return {
      availableThemes: [
        { themeName: 'empregosagro', color: 'empregosagro', logo: '/assets/images/customers/CNA.svg' },
        { themeName: 'empregatransporte', color: 'empregatransporte', logo: '/assets/images/customers/sestsenat.svg' },
        { themeName: 'vivae', color: 'vivae', logo: '/assets/images/customers/viva-e.png' },
        { themeName: 'portalfenacon', color: 'portalfenacon', logo: '/assets/images/customers/portalfenacon.png', secondary: '#4196B7' },
        { themeName: 'pwfglobal', color: 'pwfglobal', logo: '/assets/images/customers/wfglobal-logo.png' },
        { themeName: 'acats', color: 'acats', logo: '/assets/images/customers/acats.png', secondary: '#2D5AA4' },
        { themeName: 'acate', color: 'acate', logo: '/assets/images/customers/acate-verde.png' },
        { themeName: 'coursera', color: 'coursera', logo: '/assets/images/customers/coursera_white.png' },
        { themeName: 'govsp', color: 'govsp', logo: '/assets/images/customers/govsp-white.png' },
        { themeName: 'govmg', color: 'govmg', logo: '/assets/images/customers/govmg-white.png' },
        { themeName: 'govmt', color: 'govmt', logo: '/assets/images/customers/govmt-white.png' },
        { themeName: 'goves', color: 'goves', logo: '/assets/images/customers/goves-white.png' },
        { themeName: 'senacsc', color: 'senacsc', logo: '/assets/images/customers/senacsc-white.png' },
        { themeName: 'decolaentregadores', color: 'decolaentregadores', logo: '/assets/images/customers/decolaentregadores-white.png' }
      ],
      language: null
    }
  },
  methods: {
    onLanguage (val) {
      this.language = val
    }
  },
  created () {
    this.appliedTheme = Object.prototype.hasOwnProperty.call(this.$route.query, 'theme') && this.availableThemes.filter(theme => theme.themeName === this.$route.query.theme)[0]
    // eslint-disable-next-line no-undef
    zE('webWidget', 'hide')
  },
  beforeRouteLeave (to, from, next) {
    // eslint-disable-next-line no-undef
    zE('webWidget', 'show')
    next()
  }
}
</script>
<template>
  <auth-template @language="onLanguage" :title="$t('global:welcome')" :subtitle="$t('registration.page:subtitle')" :color="appliedTheme.color? appliedTheme.color : 'defaultLogin'" :logo="appliedTheme.logo">
    <template v-slot:image>
      <img :src="appliedTheme ? `/assets/images/customers/welcome-${appliedTheme.themeName}.png`: '/assets/images/customers/welcome-plooral.png'" height="100%" />
    </template>
    <template v-slot:form>
      <registration-form :language="language"/>
    </template>
  </auth-template>
</template>
<style lang="scss">
</style>
