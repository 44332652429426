<script>
import { required, minLength, maxLength, email } from 'vuelidate/lib/validators'
import InputField from '@/components/general/InputField'
import SelectField from '@/components/general/SelectField'
import staticWorkspaces from '@/support/static-workspaces/workspaces.json'

export default {
  name: 'RegistrationForm',
  components: {
    SelectField,
    InputField
  },
  props: {
    language: {
      type: String
    }
  },
  data () {
    return {
      availableThemes: [
        { themeName: 'empregosagro', color: 'empregosagro', logo: '/assets/images/customers/CNA.svg', secondary: '#62B55A' },
        { themeName: 'empregatransporte', color: 'empregatransporte', logo: '/assets/images/customers/sestsenat.svg', secondary: '#2e72e0' },
        { themeName: 'vivae', color: 'vivae', logo: '/assets/images/customers/viva-e.png', secondary: '#72009C' },
        { themeName: 'portalfenacon', color: 'vivae', logo: '/assets/images/customers/portalfenacon.png', secondary: '#4196B7' },
        { themeName: 'pwfglobal', color: 'pwfglobal', logo: '/assets/images/customers/wfglobal-logo.png', secondary: '#AA2721' },
        { themeName: 'acats', color: 'acats', logo: '/assets/images/customers/acats.png', secondary: '#2D5AA4' },
        { themeName: 'acate', color: 'acate', logo: '/assets/images/customers/acate-verde.png' },
        { themeName: 'coursera', color: 'coursera', logo: '/assets/images/customers/coursera_white.png' },
        { themeName: 'govsp', color: 'govsp', logo: '/assets/images/customers/govsp-white.png' },
        { themeName: 'govmt', color: 'govmt', logo: '/assets/images/customers/govmt-white.png' },
        { themeName: 'govmg', color: 'govmg', logo: '/assets/images/customers/govmg-white.png' },
        { themeName: 'goves', color: 'goves', logo: '/assets/images/customers/goves-white.png' },
        { themeName: 'senacsc', color: 'senacsc', logo: '/assets/images/customers/senacsc-white.png' },
        { themeName: 'decolaentregadores', color: 'decolaentregadores', logo: '/assets/images/customers/decolaentregadores-white.png' }
      ],
      theme: '',
      attemptingLogin: false,
      inviteId: null,
      companyName: null,
      avatar: null,
      formData: {
        name: null,
        lastName: null,
        email: null,
        password: null,
        region: null,
        passwordConfirmation: null,
        useTermConfirmation: null,
        emailResponse: null
      }
    }
  },
  created () {
    const language = localStorage.getItem('vuex') && JSON.parse(localStorage.getItem('vuex')).language
    this.formData.region = language === 'pt-BR' || language === '' ? 'BRA' : 'USA'
    if (this.$route.query) {
      this.formData.email = this.$route.query.email
      this.inviteId = this.$route.query.invite_id
      this.companyName = this.$route.query.company_name
      this.avatar = this.$route.query.avatar
    }
    if (this.$route.query.theme) this.theme = '?theme=' + this.$route.query.theme

    this.appliedTheme = Object.prototype.hasOwnProperty.call(this.$route.query, 'theme') && this.availableThemes.filter(theme => theme.themeName === this.$route.query.theme)[0]
  },
  validations: {
    formData: {
      name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(160)
      },
      lastName: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(160)
      },
      email: {
        required,
        email,
        minLength: minLength(3),
        maxLength: maxLength(160),
        notUsedEmail: function (value) {
          if (!this.formData.emailResponse) return true
          if (this.formData.emailResponse.status === 200) return false
          return true
        },
        isEnliztEmail: function (val) {
          if (!this.formData.emailResponse) return true
          if (this.formData.emailResponse.status === 400 || this.formData.emailResponse.status === 403 || ['enlizt.com', 'enlizt.com.br', 'enlizt.br'].includes(val.split('@')[1])) return false
          if (this.formData.emailResponse.status === 404) return true
          return true
        }
      },
      password: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(20),
        passwordIsValid: function () {
          return this.passwordRulesValidation(this.formData.password, 'valid')
        },
        passwordContainSpecialCharacter: function () {
          return this.passwordRulesValidation(this.formData.password, 'special')
        },
        passwordContainUpperCharacter: function () {
          return this.passwordRulesValidation(this.formData.password, 'upper')
        },
        passwordContainLowerCharacter: function () {
          return this.passwordRulesValidation(this.formData.password, 'lower')
        },
        passwordContainNumber: function () {
          return this.passwordRulesValidation(this.formData.password, 'number')
        }
      },
      passwordConfirmation: {
        required,
        maxLength: maxLength(20),
        passwordConfirmation: function () {
          return this.formData.password === this.formData.passwordConfirmation
        }
      },
      useTermConfirmation: {
        required: value => !!value
      }
    }
  },
  methods: {
    validateInvite () {
      if (!this.inviteId) return
      this.$store.dispatch('attemptGetInvite', this.inviteId)
        .then(({ data }) => {
          if (data) {
            if (data.registered) {
              this.$store.dispatch('attemptSetFeedbackMsg', {
                type: 'error',
                title: this.$t('registration.form:user_already_registered')
              })
              return
            }
            if (data.email !== this.formData.email) {
              this.$store.dispatch('attemptSetFeedbackMsg', {
                type: 'error',
                title: this.$t('registration.form:invalid_email')
              })
              return
            }
            this.acceptInvite()
          }
        })
        .catch((err) => {
          if (err) {
            this.$store.dispatch('attemptSetFeedbackMsg', {
              type: 'error',
              title: this.$t('registration.form:already_registered')
            })
          }
        })
    },
    onChangeRegion (value) {
      this.formData.region = value
    },
    acceptInvite () {
      this.register(false)
        .then(() => {
          this.$store.dispatch('attemptAcceptInvite', { inviteId: this.inviteId })
            .then(res => {
              this.$store.dispatch('attemptSetFeedbackMsg', {
                type: 'success',
                title: this.$t('registration.form:success')
              })
              this.login()
            })
            .catch(err => {
              if (err && err.response && err.response.data && err.response.data.error) {
                if (err.response.data.error.includes('email_already_registered')) {
                  this.$store.dispatch('attemptSetFeedbackMsg', {
                    type: 'error',
                    title: this.$t('registration.form:already_registered')
                  })
                }
                if (err.response.data.error.includes('expiredInvite')) {
                  this.$store.dispatch('attemptSetFeedbackMsg', {
                    type: 'error',
                    title: this.$t('registration.form:expired')
                  })
                }
                if (err.response.data.error.includes('invalidInvite')) {
                  this.$store.dispatch('attemptSetFeedbackMsg', {
                    type: 'error',
                    title: this.$t('registration.form:invalid')
                  })
                }
              } else {
                this.$store.dispatch('attemptSetFeedbackMsg', {
                  type: 'error',
                  title: this.$t('registration.form:error')
                })
              }
            })
        })
    },
    register (login = true) {
      const { useTermConfirmation, ...payload } = this.formData
      payload.language = localStorage.getItem('vuex') && JSON.parse(localStorage.getItem('vuex')).language
      return this.$store.dispatch('attemptRegisterUser', payload)
        .then(res => {
          if (login) {
            this.$store.dispatch('attemptSetFeedbackMsg', {
              type: 'success',
              title: this.$t('registration.form:success')
            })
            this.login()
          }
          return res
        })
        .catch(err => {
          if (err.response.data.msg.includes('email must be unique')) {
            this.$router.push({ name: 'auth.login', query: this.theme === '' ? {} : { theme: this.theme.split('=')[1] } })
            this.$store.dispatch('attemptSetFeedbackMsg', {
              type: 'error',
              timeout: -1,
              title: this.$t('registration.form:already.registered')
            })
          } else if (err && err.response && err.response.data && err.response.data.error) {
            if (err.response.data.error.includes('email_already_registered')) {
              this.$store.dispatch('attemptSetFeedbackMsg', {
                type: 'error',
                title: this.$t('email_already_registered')
              })
            }
          } else {
            this.$store.dispatch('attemptSetFeedbackMsg', {
              type: 'error',
              title: this.$t('registration.form:error')
            })
          }
        })
        .finally(() => {
          this.attemptingLogin = false
        })
    },
    async prepareUserData (data) {
      try {
        const newUserData = await this.$store.dispatch('attemptSynchronizeUser', data)
        this.$store.commit('updateUser', newUserData.data)
        await this.$store.dispatch('attemptGetUserWorkspaces')
      } catch (error) {
        await this.$store.dispatch('attemptGetUserWorkspaces', true)
        this.$store.commit('updateUser', data)
      }
    },
    async login () {
      try {
        this.attemptingLogin = true
        const response = await this.$store.dispatch('attemptPerformLogin', { email: this.formData.email, password: this.formData.password, workspace: this.$route.query.theme })
        this.setPlooralGlobalToken(response.data.token)
        const userData = response.data.user
        if (response.data) {
          try {
            await this.prepareUserData(response.data)
            this.$store.getters.getUserWorkspaces.map(workspace => {
              if (this.$route.name.includes(workspace.type)) {
                if (workspace.type === 'individual' || (this.$route.params.companyDomain && this.$route.params.companyDomain.toLowerCase() === workspace.subdomain.replace(/\s+/g, '').toLowerCase())) {
                  this.$store.commit('setSelectedWorkspace', workspace)
                }
              }
            })
            if (Object.prototype.hasOwnProperty.call(this.$route.query, 'theme')) {
              const aggregator = staticWorkspaces.filter(aggregator => aggregator.subdomain === this.$route.query.theme)
              localStorage.setItem('availableAggregators', JSON.stringify(aggregator))
              this.$router.push({ name: 'newaccount.home.welcome', query: { aggregator: aggregator[0].subdomain, isInvite: !!this.inviteId } })
            } else if (Object.prototype.hasOwnProperty.call(this.$route.query, 'subdomain')) {
              this.$router.push({ name: 'newaccount.home.welcome', query: { company: this.$route.query.subdomain, help: this.$route.query.help, isInvite: !!this.inviteId } })
            } else this.$router.push({ name: 'newaccount.home.welcome', query: { isInvite: !!this.inviteId } })
            this.attemptingLogin = false
          } catch (error) {
            this.attemptingLogin = false
          }
        }
        this.changeLanguage(userData.language)
        this.$store.commit('setLanguageIsLoaded', true)
      } catch (error) {
        this.attemptingLogin = false
      }
    },
    verifyLogin () {
      this.attemptingLogin = true
      if (this.inviteId) {
        this.validateInvite()
      } else {
        this.register()
      }
    }
  },
  watch: {
    language (val) {
      this.formData.region = val === 'pt-BR' ? 'BRA' : 'USA'
    },
    'formData.email' (input) {
      if (input && email(input) && input.length > 0) {
        this.debounceEvent(async () => {
          try {
            this.formData.emailResponse = await this.$store.dispatch('attemptVerifyEmail', input)
          } catch (err) {
            this.formData.emailResponse = err.response
          }
        }, 400)
      }
    }
  }
}
</script>
<template>
  <div class="registration--container">
    <v-card class="registration-form--wrapper">
      <div style="text-align: center" v-if="!!avatar">
        <v-avatar class="round" size="128">
          <v-img :src="avatar" height="128px" width="128px"></v-img>
        </v-avatar>
      </div>
      <h4>{{ $t('registration.form:title') + (companyName ? ' | ' + companyName : '') }}</h4>
      <p class="registration-form-subtitle">
      {{$t('registration.form:subtitle.1')}} <router-link :to='`/login${theme}`'>{{$t('registration.form:subtitle')}} </router-link>
    </p>
      <input-field
        outlined
        class="mt-10"
        v-model="formData.name"
        :label="$t('registration.form:name')"
        :validation="$v.formData.name"
        :hide-validation="!$v.formData.name.$error && isMobile"
      />
      <input-field
        outlined
        v-model="formData.lastName"
        :label="$t('registration.form:lastName')"
        :validation="$v.formData.lastName"
        :hide-validation="!$v.formData.lastName.$error && isMobile"
      />
      <input-field
        outlined
        v-model="formData.email"
        :disabled="!!formData.email && !!inviteId"
        :label="$t('registration.form:email')"
        :validation="$v.formData.email"
        :hide-validation="!$v.formData.email.$error && isMobile"
      />
      {{ $v.formData.notUsedEmail }}
      <select-field
        outlined
        v-model="formData.region"
        :items="[
        { text: this.$t('global:country.brazil'), value: 'BRA' },
        { text: this.$t('global:country.united.states'), value: 'USA' }
      ]"
        @change="onChangeRegion"
        :label="this.$t('settings.profile.labels:region')"
      />
      <input-field
        type="password"
        outlined
        v-model="formData.password"
        :label="$t('registration.form:password')"
        :validation="$v.formData.password"
        :hide-validation="!$v.formData.password.$error && isMobile"
      />
      <input-field
        type="password"
        outlined
        v-model="formData.passwordConfirmation"
        :label="$t('registration.form:confirmPassword')"
        :validation="$v.formData.passwordConfirmation"
        :hide-validation="!$v.formData.passwordConfirmation.$error && isMobile"
      />
      <div class="registration-form-checkbox">
        <v-checkbox
          class="body--1 mt-n4"
          v-model="formData.useTermConfirmation"
          hide-details="auto"
        />
        <p v-html="$t('registration.form:useTerm', { useTermsUrl })"></p>
      </div>
      <v-btn class="mt-10 btn transform-unset" style="color: white; font-size: 16px;" :color="appliedTheme.secondary ? appliedTheme.secondary : '#1200D3'" :disabled="$v.$invalid || !this.formData.emailResponse ||attemptingLogin" large block @click="verifyLogin">{{ $t(attemptingLogin ? 'global:wait' : 'registration.form:submit') }}</v-btn>
    </v-card>
  </div>
</template>
<style lang="scss">
.registration--container .v-input .v-label {
  color: rgba(0,0,0,.6) !important;
}
  .registration--container {
    max-width: 100%;
    margin-bottom: 20px;
    width: 515px;
    .registration-form--wrapper {
      padding: 45px 64px;
      border-radius: 10px;
      text-align: left;
      .registration-form-subtitle {
        @extend .subtitle--1;
        @extend .neutral-medium;
      }
      .registration-form-checkbox {
        display: flex;
        align-items: center;
      }
    }
    @media only screen and (max-width: 768px) {
      .registration-form--wrapper {
        border-radius: 10px 10px 0 0;
        padding: 36px 24px 80px;
      }
    }
  }
</style>
